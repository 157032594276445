import { parsePortfolio } from '@utils/helpers';
import Fade from 'react-reveal/Fade';
import SectionTitle from './home/sectionTitle';
import WorkExample from './home/workExample';
import classNames from 'classnames';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

function Works({ children, id, protfolios, withTitle }) {
	return (
		<section className="section" id={id}>
			<div className="section section-head has-text-centered">
				<div className="container">
					{withTitle && <SectionTitle title="Work" />}
					<div className="section container">
						<ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
							<Masonry gutter="40px">
								{protfolios.map((args, i) => (
									<Fade key={i} opposite>
										<WorkExample {...args} />
									</Fade>
								))}
							</Masonry>
						</ResponsiveMasonry>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Works;
