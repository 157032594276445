import Fade from 'react-reveal/Fade';
import SectionTile from './home/serviceTIle';
import { svg } from '../utils/constants';

function Services({ children, id }) {
	return (
		<section className="" id={id}>
			<div className="section container">
				<div className="columns is-multiline">
					{servicesTiles.map((tileProps, i) => (
						<div key={i} className="column is-one-third">
							<Fade opposite>
								<SectionTile {...tileProps} />
							</Fade>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}

const servicesTiles = [
	{
		title: 'Strategy',
		icon: svg.strategy,
		description:
			'I develop Web applications for interactive, spicy solutions and transform great ideas into usable Application for beautiful products'
	},
	{
		title: 'Code',
		icon: svg.code,
		description:
			"Using the latest technologies and it's benefits: faster, smarter, simpler and more robust. Nodejs, React, React-Native, GraphQL, Vue.js, AWS..."
	},
	{
		title: 'Performance',
		icon: svg.performance,
		description:
			'Have you visited a website from an old smart phone under a slow network connection? And then, have you visited that website ever again? '
	},
	{
		title: 'UX/UI',
		description:
			'Interfaces focused on content structure, intuitive UI patterns and simple interactions with fully responsive design',
		icon: svg.ui
	},
	{
		title: 'Creativity',
		description:
			'Your ideas will be mixed with proven technologies to achieve your business goals in the best way.',
		icon: svg.creative
	},
	{
		title: 'SEO',
		description:
			'Does Google hate your website? I will figure out why and get you more traffic',
		icon: svg.seo
	}
];

export default Services;
