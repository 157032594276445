export const articles = [
	{
		title: '8 Tips On How To Optimise Your Website’s Loading Speed',
		date: '1 Jan 2016',
		description:
			'Speed matters. We are in an age where broadband connections and new mobile generations ',
		body: `Speed matters. We are in an age where broadband connections and new mobile generations such as 4G and 5G
                are rolled out
                at relentless rhythms.However, these leaps forward are not an excuse to keep loading our websites as
                packhorses loaded
                for the mountains and not taking care at improving page speeds.A website that…`,
		url: '/article',
		slug: '8-tips-on-how-to-optimise-your-website-loading-page'
	},
	{
		title: 'How much does it cost to develop a Web Application?',
		date: '28th November 2018',
		description:
			'Speed matters. We are in an age where broadband connections and new mobile generations ',
		body: `One of the most frequent questions that I receive is “How much does a Web Application cost?”, or even
                “What’s a ballpark cost for building a web app?”. This is an entirely reasonable question, but despite how much I’d love to
                be able to provide a quick answer, it is a tough one without having…`,
		url: '/article',
		slug: 'how-much-does-it-cost-to-develop-a-web-application'
	},
	{
		title: 'Coworking in Berlin: a map to find the best places',
		date: '28th November 2018',
		description:
			'Speed matters. We are in an age where broadband connections and new mobile generations ',
		body: `(link back soon) Having recently relocated to Berlin, and being a Web Developer, one of my priorities
                was to find a place to work from. Long gone are the days when I used to work from the living room or the kitchen
                (well, at least on a full-time basis). For that there’s Coworking, such an…`,
		url: '/article',
		description:
			'Speed matters. We are in an age where broadband connections and new mobile generations ',
		slug: 'coworking-in-berlin-a-map-to-find-the-best-place'
	}
];

export const grayPixel =
	'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==';
export const blackPixel = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
export const greenPixel =
	'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mO02Vz4HwAE9AJhcLBN6AAAAABJRU5ErkJggg==';

export const svg = {
	strategy: (
		<svg
			aria-hidden="true"
			data-prefix="fas"
			height="80px"
			data-icon="chart-line"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512">
			<path
				fill="currentColor"
				d="M496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM464 96H345.94c-21.38 0-32.09 25.85-16.97 40.97l32.4 32.4L288 242.75l-73.37-73.37c-12.5-12.5-32.76-12.5-45.25 0l-68.69 68.69c-6.25 6.25-6.25 16.38 0 22.63l22.62 22.62c6.25 6.25 16.38 6.25 22.63 0L192 237.25l73.37 73.37c12.5 12.5 32.76 12.5 45.25 0l96-96 32.4 32.4c15.12 15.12 40.97 4.41 40.97-16.97V112c.01-8.84-7.15-16-15.99-16z"
			/>
		</svg>
	),
	code: (
		<svg
			aria-hidden="true"
			data-prefix="fas"
			height="80px"
			data-icon="code"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 640 512">
			<path
				fill="currentColor"
				d="M278.9 511.5l-61-17.7c-6.4-1.8-10-8.5-8.2-14.9L346.2 8.7c1.8-6.4 8.5-10 14.9-8.2l61 17.7c6.4 1.8 10 8.5 8.2 14.9L293.8 503.3c-1.9 6.4-8.5 10.1-14.9 8.2zm-114-112.2l43.5-46.4c4.6-4.9 4.3-12.7-.8-17.2L117 256l90.6-79.7c5.1-4.5 5.5-12.3.8-17.2l-43.5-46.4c-4.5-4.8-12.1-5.1-17-.5L3.8 247.2c-5.1 4.7-5.1 12.8 0 17.5l144.1 135.1c4.9 4.6 12.5 4.4 17-.5zm327.2.6l144.1-135.1c5.1-4.7 5.1-12.8 0-17.5L492.1 112.1c-4.8-4.5-12.4-4.3-17 .5L431.6 159c-4.6 4.9-4.3 12.7.8 17.2L523 256l-90.6 79.7c-5.1 4.5-5.5 12.3-.8 17.2l43.5 46.4c4.5 4.9 12.1 5.1 17 .6z"
			/>
		</svg>
	),
	performance: (
		<svg
			aria-hidden="true"
			data-prefix="fas"
			height="80px"
			data-icon="tachometer-alt"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 576 512">
			<path
				fill="currentColor"
				d="M288 32C128.94 32 0 160.94 0 320c0 52.8 14.25 102.26 39.06 144.8 5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2C561.75 422.26 576 372.8 576 320c0-159.06-128.94-288-288-288zm0 64c14.71 0 26.58 10.13 30.32 23.65-1.11 2.26-2.64 4.23-3.45 6.67l-9.22 27.67c-5.13 3.49-10.97 6.01-17.64 6.01-17.67 0-32-14.33-32-32S270.33 96 288 96zM96 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm48-160c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm246.77-72.41l-61.33 184C343.13 347.33 352 364.54 352 384c0 11.72-3.38 22.55-8.88 32H232.88c-5.5-9.45-8.88-20.28-8.88-32 0-33.94 26.5-61.43 59.9-63.59l61.34-184.01c4.17-12.56 17.73-19.45 30.36-15.17 12.57 4.19 19.35 17.79 15.17 30.36zm14.66 57.2l15.52-46.55c3.47-1.29 7.13-2.23 11.05-2.23 17.67 0 32 14.33 32 32s-14.33 32-32 32c-11.38-.01-20.89-6.28-26.57-15.22zM480 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"
			/>
		</svg>
	),
	ui: (
		<svg
			aria-hidden="true"
			data-prefix="fas"
			height="80px"
			data-icon="user-plus"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 640 512">
			<path
				fill="currentColor"
				d="M624 208h-64v-64c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v64h-64c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm-400 48c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
			/>
		</svg>
	),
	creative: (
		<svg
			aria-hidden="true"
			height="80px"
			data-prefix="fas"
			data-icon="lightbulb"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 352 512">
			<path
				fill="currentColor"
				d="M96.06 454.35c.01 6.29 1.87 12.45 5.36 17.69l17.09 25.69a31.99 31.99 0 0 0 26.64 14.28h61.71a31.99 31.99 0 0 0 26.64-14.28l17.09-25.69a31.989 31.989 0 0 0 5.36-17.69l.04-38.35H96.01l.05 38.35zM0 176c0 44.37 16.45 84.85 43.56 115.78 16.52 18.85 42.36 58.23 52.21 91.45.04.26.07.52.11.78h160.24c.04-.26.07-.51.11-.78 9.85-33.22 35.69-72.6 52.21-91.45C335.55 260.85 352 220.37 352 176 352 78.61 272.91-.3 175.45 0 73.44.31 0 82.97 0 176zm176-80c-44.11 0-80 35.89-80 80 0 8.84-7.16 16-16 16s-16-7.16-16-16c0-61.76 50.24-112 112-112 8.84 0 16 7.16 16 16s-7.16 16-16 16z"
			/>
		</svg>
	),
	state: (
		<svg
			aria-hidden="true"
			height="80px"
			data-prefix="fas"
			data-icon="userinterface"
			viewBox="0 0 479 480"
			role="img"
			xmlns="http://www.w3.org/2000/svg">
			<path d="m368.5 368h-16v32h-144v16h144v24c0 13.253906-10.746094 24-24 24h-176c-8.574219-.011719-16.496094-4.582031-20.800781-12l-13.855469 8c7.171875 12.355469 20.371094 19.972656 34.65625 20h176c22.082031-.027344 39.972656-17.917969 40-40zm0 0" />
			<path d="m232.5 432h16v16h-16zm0 0" />
			<path d="m280.5 216v-16h-24.40625c-3.988281-51.050781-44.542969-91.605469-95.59375-95.59375v-24.40625h-16v24.40625c-5.390625.414062-10.738281 1.25-16 2.496094v-42.902344h128v-16h-128v-8c0-13.253906 10.746094-24 24-24h176c5.84375 0 11.488281 2.132812 15.871094 6l10.585937-12c-7.304687-6.449219-16.714843-10.00390625-26.457031-10h-176c-22.082031.0273438-39.972656 17.917969-40 40v72c-36.023438 15.089844-60.574219 49.0625-63.59375 88h-24.40625v16h24.425781c3.773438 51.148438 44.425781 91.828125 95.574219 95.632812v24.367188h16v-24.367188c51.148438-3.804687 91.800781-44.484374 95.574219-95.632812zm-136 64v15.632812c-11.160156-1.003906-22.019531-4.140624-32-9.234374v-14.398438c0-22.089844 17.910156-40 40-40s40 17.910156 40 40v14.398438c-9.976562 5.101562-20.839844 8.242187-32 9.257812v-15.65625zm-16-88c0-13.253906 10.746094-24 24-24s24 10.746094 24 24-10.746094 24-24 24-24-10.746094-24-24zm82.664062 81.601562c-.863281.796876-1.785156 1.4375-2.664062 2.175782v-3.777344c-.019531-20.898438-11.675781-40.046875-30.230469-49.664062 12.851563-10.824219 17.566407-28.527344 11.804688-44.308594-5.761719-15.785156-20.773438-26.285156-37.574219-26.285156s-31.8125 10.5-37.574219 26.285156c-5.761719 15.78125-1.046875 33.484375 11.804688 44.308594-18.554688 9.617187-30.210938 28.765624-30.230469 49.664062v3.816406c-.886719-.726562-1.808594-1.410156-2.664062-2.175781-16.578126-14.867187-26.910157-35.460937-28.910157-57.640625h15.574219v-16h-15.59375c3.910156-42.234375 37.359375-75.683594 79.59375-79.59375v15.59375h16v-15.59375c42.234375 3.910156 75.683594 37.359375 79.59375 79.59375h-15.59375v16h15.574219c-2 22.179688-12.332031 42.773438-28.910157 57.640625zm0 0" />
			<path d="m456.5 144c4.417969 0 8-3.582031 8-8v-96c0-4.417969-3.582031-8-8-8h-176c-4.417969 0-8 3.582031-8 8v96c0 4.417969 3.582031 8 8 8zm-168-96h160v80h-160zm0 0" />
			<path d="m304.5 64h32v16h-32zm0 0" />
			<path d="m352.5 64h80v16h-80zm0 0" />
			<path d="m304.5 96h128v16h-128zm0 0" />
			<path d="m455.085938 160c-6.195313.011719-12.171876 2.285156-16.800782 6.398438l-69.785156 61.523437v-67.921875h-16v80h2.3125l-45.511719 40.160156c-29.609375 1.695313-52.765625 26.179688-52.800781 55.839844v14.113281c.03125 7.9375-4.457031 15.207031-11.574219 18.726563-3.324219 1.65625-5.070312 5.382812-4.21875 8.996094.851563 3.613281 4.078125 6.167968 7.792969 6.164062h56c29.660156-.035156 54.144531-23.191406 55.839844-52.800781l113.808594-128.976563c6.605468-7.492187 8.210937-18.160156 4.097656-27.265625-4.109375-9.105469-13.171875-14.957031-23.160156-14.957031zm-150.585938 208h-36.585938c3.019532-5.476562 4.597657-11.632812 4.585938-17.886719v-14.113281c.027344-20.839844 16.027344-38.179688 36.800781-39.871094l35.070313 35.070313c-1.6875 20.773437-19.03125 36.777343-39.871094 36.800781zm157.648438-176.359375-110.007813 124.679687-27.960937-27.953124 124.679687-110.015626c3.730469-3.273437 9.359375-3.089843 12.871094.417969 3.507812 3.511719 3.691406 9.140625.417969 12.871094zm0 0" />
			<path d="m8.5 440h176c4.417969 0 8-3.582031 8-8v-64c0-4.417969-3.582031-8-8-8h-56v-40h-16v40h-104c-4.417969 0-8 3.582031-8 8v64c0 4.417969 3.582031 8 8 8zm8-64h160v48h-160zm0 0" />
			<path d="m32.5 392h40v16h-40zm0 0" />
			<path d="m88.5 392h72v16h-72zm0 0" />
			<path d="m8.5 112h80c4.417969 0 8-3.582031 8-8v-64c0-4.417969-3.582031-8-8-8h-80c-4.417969 0-8 3.582031-8 8v64c0 4.417969 3.582031 8 8 8zm8-64h64v48h-64zm0 0" />
			<path d="m32.5 64h32v16h-32zm0 0" />
		</svg>
	),
	seo: (
		<svg
			aria-hidden="true"
			data-prefix="fas"
			data-icon="space-shuttle"
			height="80px"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 640 512">
			<path
				fill="currentColor"
				d="M592.604 208.244C559.735 192.836 515.777 184 472 184H186.327c-4.952-6.555-10.585-11.978-16.72-16H376C229.157 137.747 219.403 32 96.003 32H96v128H80V32c-26.51 0-48 28.654-48 64v64c-23.197 0-32 10.032-32 24v40c0 13.983 8.819 24 32 24v16c-23.197 0-32 10.032-32 24v40c0 13.983 8.819 24 32 24v64c0 35.346 21.49 64 48 64V352h16v128h.003c123.4 0 133.154-105.747 279.997-136H169.606c6.135-4.022 11.768-9.445 16.72-16H472c43.777 0 87.735-8.836 120.604-24.244C622.282 289.845 640 271.992 640 256s-17.718-33.845-47.396-47.756zM488 296a8 8 0 0 1-8-8v-64a8 8 0 0 1 8-8c31.909 0 31.942 80 0 80z"
			/>
		</svg>
	)
};

export const BREAK_POINTS = {
	mobile: 414,
	tablet: 768
};
