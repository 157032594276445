import Image from 'next/image';

function Hero({ salutation, name, description, subtitle }) {
	return (
		<section className="hero is-link is-fullheight is-fullheight-with-navbar diagonal-hero-bg">
			<div className="hero-body">
				<div className="small" />
				<div className="medium" />
				<div className="big" />
				<div className="container css-typing">
					{salutation && <p className="is-5">{salutation}</p>}
					{name && <h1 className="title is-1"> {name} </h1>}
					{description && <h2 className="subtitle is-6">{description}</h2>}
					{subtitle && <p className="subtitle is-4">{subtitle}</p>}
				</div>
				{/* <img src="img/alaeddine-messadi.png" /> */}
				{/* <img src="img/alaeddine-messadi-bg.png" /> */}
				{/* <img src="img/alaeddine-messadi-1.png" /> */}
			</div>
		</section>
	);
}

export default Hero;
